<template>
	<div class="lesson-card" :class="{locked: (isLessonEmpty || (locked && !sell)) && !editMode && !live}">
		<div class="lesson-card__body">
			<div class="lesson-card__body--icon" :class="{completed: isLessonCompleted && !editMode}">
				<icon class="lesson-card__body--icon--item" :icon="lessonIcon" colour="transparent" />
			</div>

			<div class="lesson-card__body--text">
				<div class="lesson-card__body--text-title">
					{{ lesson.title }}
				</div>
				<div class="lesson-card__body--text-subtitle " :class="{activities: !live}">
					<template v-if="!live">
						<icon icon="v2-go" colour="transparent" />
						{{ lesson.activityProgress || 0 }}/{{ lesson.activityCount }} Activities
					</template>
					<template v-else>
						{{ getScheduleString }}
					</template>
				</div>
			</div>
		</div>
		<V2Button
			class="lesson-card__btn"
			:disabled="isLessonEmpty && !editMode"
			:class="{
				completed: isLessonCompleted && !editMode,
				active: active && !editMode && !live,
			}"
			:variant="editMode ? 'outline' : ''"
			@click="openLesson"
		>
			{{ btnText }}
		</V2Button>
	</div>
</template>

<script>
	import Icon               from '@/components/ui/Icon';
	import getStripe             from '@/services/stripe';
	import api                   from '@/services/api';
	import V2Button              from '@/components/v2/ui/V2Button';

	export default {

		components: {
			Icon,
			V2Button
		},

		props: {
			course: {
				type: Object,
				required: true
			},
			lesson: {
				type: Object,
				required: true
			},
			active: {
				type: Boolean,
				required: true
			},
			sell: {
				type: Boolean,
				default: false
			},
			locked: {
				type: Boolean,
				default: false
			},
			editMode: {
				type: Boolean,
				default: false
			},
			adminMode: {
				type: Boolean,
				default: false
			},
			live: {
				type: Boolean,
				default: false
			}
		},

		computed: {

			isLessonEmpty () {
				return this.lesson.activityCount === 0 && (!this.lesson.deals || this.lesson.deals?.length === 0);
			},

			isLessonCompleted () {
				return this.lesson.isComplete;
			},

			activityType () {
        if (!this.lesson?.activities) {
          return null;
        }
				return this.lesson.activities[0]?.type || null;
			},

			getScheduleString () {

				// eslint-disable-next-line no-unreachable
				if (!this.lesson.live) {
					return false;
				}
				if (!this.lesson?.schedule?.startsAt) {
					return 'Not scheduled';
				}
				const startsAt = this.lesson?.schedule?.startsAt;
				const startedAt = this.lesson?.schedule?.startedAt;
				const endedAt = this.lesson?.schedule?.endedAt;
				if (endedAt) {
					const time = this.$store.getters['auth/getTimeInLocalTimezone']({
						time: endedAt
					});
					return `Ended at ${time}`;
				}
				if (startedAt && !endedAt) {
					return 'Live now';
				}
				const time = this.$store.getters['auth/getTimeInLocalTimezone']({
					time: startsAt
				});
				return `Starts at ${time}`;
			},

			btnText () {
        if (this.sell) {
          return 'Join Course to start';
        }

        if (this.live) {
          return 'View lesson';
        }

        if (this.editMode) {
          return 'Edit Lesson';
        }

        if (this.isLessonEmpty || this.locked) {
          return 'Locked';
        }

        if (this.active) {
          return this.getBtnTextByType();
        }

        if (this.isLessonCompleted) {
          return 'Completed';
        }

				return 'Start';
			},

			lessonIcon () {
        if (this.isLessonCompleted) {
          return 'v2-lesson-completed';
        }

        if (this.activityType === 'video') {
          return 'v2-lesson-video';
        }

        if (this.activityType === 'embeddedPresentation') {
          return 'v2-lesson-presentation';
        }

				return 'v2-lesson-quiz';
			}
		},

		methods: {

			async joinCourse () {
				const course = await api.courses.joinCourseById({
					courseId: this.course.id
				});
				if (!course) {
					return false;
				}
				if (!course.checkout) {
					// no fee required, no stripe checkout
					this.$router.push(`/my-courses/${this.course.id}`);
					return;
				}
				const stripe = await getStripe();
				stripe.redirectToCheckout({
					sessionId: course.checkout
				});
			},

			openLesson () {

        if (this.sell) {
          this.joinCourse();
          return;
        } else if (this.editMode) {
          this.$router.push(`/${this.adminMode ? 'admin' : 'teaching'}/courses/${this.lesson.courseId}/lessons/${this.lesson.id}`);
          return;
        }

				this.$router.push(`/my-courses/${this.lesson.courseId}/lessons/${this.lesson.id}`);
			},

			getBtnTextByType () {
				// if (this.activityType === 'video') {
				//   return 'Watch Video Lesson';
				// }

				// if (this.activityType === 'video') {
				//   return 'Take Quiz';
				// }

				return 'Continue';
			}
		}
	};
</script>

<style lang="scss" scoped>

.live {
  width: 9px;
  height: 9px;
  border-radius: 40px;
  background: $c-brand-red
}

.lesson-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $c-gray-d2;
  border-radius: 4px;
  justify-content: space-between;

  &.locked {
    opacity: 30%;
    pointer-events: none;
    cursor: not-allowed;

    .lesson-card__btn {
      background: $c-gray-d4;
    }
  }

  &__body {
    display: flex;
    gap: 16px;

    &--icon {
      width: 50px;
      min-width: 50px;
      height: 100%;
      max-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $c-dark-navy;
      border-radius: 2px;

      &.completed {
        background-color: $c-green-l;
      }

      &--item {
        width: 36px;
        height: 36px;
      }
    }

    &--text {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-title {
        @include font(18px, $c-dark-navy, 500, 23px);
        @include text-overflow(1);
        word-break: break-word;
      }

      &-subtitle {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 4px 0px;
        background: $c-white-l2;
        @include font(14px, $c-dark-navy, 500, 18px);

        &:not(.activities) {
          @include text-overflow(2);
          word-break: break-word;
        }
      }
    }
  }

  &__btn {
    font-family: 'DM Sans', sans-serif !important;
    font-size: 18px !important;
    color: $c-white-l !important;
    font-weight: 500 !important;
    line-height: 23px !important;
    padding: 6px 20px !important;
    border-radius: 4px !important;
    outline: inherit !important;
    background: $c-dark-navy !important;
    border: none !important;
    width: 100% !important;
    margin-top: 12px !important;

    &.completed {
      background: $c-gray-d4 !important;
      opacity: 0.75 !important;
    }

    &.active {
      background-color: $c-red !important;
    }
  }
}

</style>
