<template>
	<div data-component="course-action-list">
		<action-list
			:actions="getActionList"
			@actionClick="onActionClick"
		/>
		<portal
			to="modal"
			v-if="lessonsModalIsVisible"
		>
			<modal-inner
				@close="toggleLessonsModalIsVisible"
				heading="Lessons"
			>
				<lessons-modal
					@updateLessonsOrder="onUpdateLessonsOrder"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="duplicateModalIsVisible"
		>
			<modal-inner
				@close="toggleDuplicateModalIsVisible"
				heading="Duplicate course"
			>
				<validation-observer
					data-element="form"
					tag="form"
					ref="duplicateCourseForm"
					@submit.prevent="duplicateCourse"
				>
					<select-field
						name="Choose course type"
						:options="getDuplicateCourseTypeOptions"
						v-model="duplicateCourseType"
						rules="required"
					/>
					<actions
						:actions="getDuplicateCourseModalActions"
						@actionClick="onActionClick"
						:isWaiting="isWaiting"
					/>
				</validation-observer>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="addStudentModalIsVisible"
		>
			<modal-inner
				@close="toggleAddStudentModalIsVisible"
				heading="Add a student"
			>
				<add-student-modal />
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import ActionList          from '@/components/ui/ActionList';
	import ModalInner          from '@/components/ui/modal/ModalInner';
	import LessonsModal        from '@/components/courses/LessonsModal';
	import AddStudentModal     from '@/components/courses/AddStudentModal';
	import SelectField         from '@/components/forms/SelectField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		components: {
			ActionList,
			ModalInner,
			LessonsModal,
			AddStudentModal,
			SelectField,
			Actions
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		props: {
			course: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: undefined,
			lessonsModalIsVisible: false,
			duplicateModalIsVisible: false,
			duplicateCourseType: undefined,
			addStudentModalIsVisible: false
		}),
		computed: {
			getShowEditCourse () {
				if (this.getContext === 'browseCourses' ||
						this.getContext === 'myCourses') {
					return false;
				}
				return true;
			},
			getShowDuplicateCourse () {
				return this.getContext === 'teaching';
			},
			getShowAddStudent () {
				return this.getContext === 'admin';
			},
			getDuplicateCourseTypeOptions () {
				return [
					(this.course.live ?
						{
							text: 'Live (Keep the same)',
							value: 'live'
						} : false
					),
					(!this.course.live ?
						{
							text: 'Solo (Keep the same)',
							value: 'solo'
						} : false
					),
					(this.course.live ?
						{
							text: 'Convert to solo (This will remove live-only activities)',
							value: 'solo'
						} : false
					),
					(!this.course.live ?
						{
							text: 'Convert to live',
							value: 'live'
						} : false
					)
				].filter(Boolean);
			},
			getDuplicateCourseModalActions () {
				return {
					primary: [
						{
							text: 'Duplicate',
							type: 'submit',
							btnId: 'duplicateCourseSubmit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'toggleDuplicateModalIsVisible'
						}
					]
				};
			},
			getActionList () {
				return [
					(this.getShowEditCourse ?
						{
							text: 'Edit course',
							route: `/${this.getContextSlug}/courses/${this.getCourseId}/edit`
						} : false
					),
					(this.getShowEditCourse ?
						{
							text: 'Edit lessons',
							actionId: 'toggleLessonsModalIsVisible'
						} : false
					),
					(this.getShowEditCourse ?
						{
							text: 'View Students',
							route: `/${this.getContextSlug}/courses/${this.getCourseId}/students`
						} : false
					),
					(this.getShowDuplicateCourse ?
						{
							text: 'Duplicate',
							actionId: 'toggleDuplicateModalIsVisible'
						} : false
					),
					(this.getShowAddStudent ?
						{
							text: 'Add student',
							actionId: 'toggleAddStudentModalIsVisible'
						} : false
					)
				].filter(Boolean);
			}
		},
		methods: {
			toggleLessonsModalIsVisible () {
				this.lessonsModalIsVisible = !this.lessonsModalIsVisible;
			},
			toggleDuplicateModalIsVisible () {
				this.duplicateModalIsVisible = !this.duplicateModalIsVisible;
			},
			toggleAddStudentModalIsVisible () {
				this.addStudentModalIsVisible = !this.addStudentModalIsVisible;
			},
			onUpdateLessonsOrder (lessons) {
				this.course.lessons = lessons;
			},
			async duplicateCourse () {
				const valid = await this.$refs.duplicateCourseForm.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'duplicateCourseSubmit';
				const response = await api.teaching.duplicateCourseById({
					courseId: this.course.id,
					convertTo: this.duplicateCourseType
				});
				if (!response?.id) {
					this.isWaiting = undefined;
					return;
				}
				this.isWaiting = undefined;
				await this.$router.push(`/teaching/courses/${response.id}`);
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been duplicated'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='course-action-list'] {
    margin-bottom: 36px;

    [data-component=action-list] {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
	}
</style>
