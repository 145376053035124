<template>
	<div data-component="lessons-modal">
		<div class="page__info">
			<div class="page__info--title">
				Lessons
			</div>
			<V2Button
				v-if="this.getCourseId && this.getContext !== 'admin'"
				class="page__info--btn"
				:to="`/teaching/courses/${getCourseId}/lessons/create`"
				fit
			>
				New lesson
			</V2Button>
		</div>

		<div>
			<draggable-list
				v-if="lessons"
				:items="lessons"
				:courseId="getCourseId"
				:type="'lessons'"
				@reordered="onReordered"
			/>
		</div>
	</div>
</template>

<script>
	import DraggableList  from '@/components/ui/views/draggableList/DraggableList';
	import routeParams    from '@/mixins/routeParams';
	import api            from '@/services/api';
	import V2Button from '@/components/v2/ui/V2Button';

	export default {
		components: {
			DraggableList,
			V2Button
		},
		mixins: [routeParams],
		data: () => ({
			lessons: []
		}),
		computed: {
			getPageHeaderAction () {
				if (!this.getCourseId) {
					return undefined;
				}
				if (this.getContext === 'admin') {
					return undefined;
				}
				return {
					text: 'New lesson',
					route: `/teaching/courses/${this.getCourseId}/lessons/create`
				};
			},
			getCourseId () {
				return parseInt(this.$route.params.courseId);
			}
		},
		created: function () {
			this.getLessons();
		},
		methods: {
			async onReordered (reorderedLessons) {
				const response = await api[this.getApiRoot].reorderLesssonsByCourseId({
					courseId: this.getCourseId,
					lessons: reorderedLessons.map((lesson) => {
						return lesson.id;
					})
				});
				if (!response) {
					return false;
				}
				this.$emit('updateLessonsOrder', reorderedLessons);
				this.$store.commit('ui/showNotification', {
					notification: 'New order has been saved.'
				});
			},
			async getLessons () {
				const response = await api[this.getApiRoot].getLessonsByCourseId({
					courseId: this.getCourseId
				});
				if (!response) {
					// this.$store.commit('ui/showError');
					return false;
				}
				this.lessons = response;
				// this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>

<style lang="scss" scoped>
.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0;
    margin-bottom: 12px;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}

</style>
