<template>
	<div
		data-component="action-list"
		v-if="getActions"
	>
		<btn
			v-for="(btn, key) in getActions"
			:key="key"
			v-bind="btn"
			size="md"
			@actionClick="onActionClick"
			:block="true"
			:isWaiting="isWaiting"
			:class="[btn.variant ? btn.variant : 'filled']"
		/>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		},
		props: {
			actions: {
				type: Array,
				default: () => ([])
			},
			isWaiting: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getActions () {
				if (!this.actions?.length) {
					return false;
				}
				return this.actions;
			},
			getPrimaryActions () {
				if (!this.actions ||
						!this.actions.primary ||
						!this.actions.primary.length) {
					return false;
				}
				return this.actions.primary;
			},
			getSecondaryActions () {
				if (!this.actions ||
						!this.actions.secondary ||
						!this.actions.secondary.length) {
					return false;
				}
				return this.actions.secondary;
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='action-list'] {
    display:flex;
		justify-content:space-between;

		[data-component='btn'] {
      height: auto;
      border-radius: 4px;
      @include font(16px, $c-dark-navy, 500, 20px);
      padding: 8px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;

      &:hover:not(.disabled) {
        filter: brightness(80%);
      }

      &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
      }

      &.fit {
        width: fit-content;
      }

      &.red {
        background: $c-red;
        color: $c-white-l;
        border: 1px solid $c-red;
      }

      &.outline {
        background: $c-bg-white;
        color: $c-dark-navy;
        border: 1px solid $c-dark-navy;
      }

      &.filled {
        background: $c-dark-navy;
        color: $c-white-l;
        border: 1px solid $c-dark-navy;
      }

      &.text {
        color: $c-dark-navy;
        background: none;
        text-align: center;

        &:hover:not(.disabled) {
          background: $c-gray-l2;
          border: 1px solid $c-gray-l2;
        }
      }

      &.correct {
        background: $c-green-l2;
        color: $c-darkest-navy;
        border: 1px solid $c-green-l2;
      }

      &.incorrect {
        background: $c-red-l2;
        color: $c-darkest-navy;
        border: 1px solid $c-red-l2;
      }
		}
		[data-element='waiting'] {
			@include loader(3px);
		}
	}

</style>
