<template>
	<div>
		<course-view
			:course="course"
		/>
	</div>
</template>

<script>

	import CourseView   from '@/components/courses/CourseView';
	import routeParams  from '@/mixins/routeParams';
	import api          from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getCourseTitle) {
				return false;
			}
			return {
				title: this.getCourseTitle
			};
		},
		layout: 'v2default',
		components: {
			CourseView
		},
		mixins: [routeParams],
		data: () => ({
			course: {}
		}),
		computed: {
			getCourseTitle () {
				if (!this.course?.title) {
					return false;
				}
				return this.course.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/my-courses',
						text: 'My learning'
					},
					{
						path: `/my-courses/${this.getCourseId}`,
						text: this.getCourseTitle
					}
				];
			}
		},
		created: function () {
			this.setCourse();
		},
		methods: {
			async setCourse () {
				const course = await api.courses.getCourseById({
					courseId: this.getCourseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}

				const teacherProfile = await api.teacherProfiles.getTeacherProfileById({
					profileId: course.teacherProfileId
				});

        if (!teacherProfile) {
					this.$store.commit('ui/showError');
					return false;
				}

				course.teacherProfile = teacherProfile;

				this.course = course;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-route] {}
</style>
