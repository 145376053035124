<template>
	<div data-component="action-group-row">
		<p
			v-if="getInfoText"
			data-element="info-text"
		>
			{{ getInfoText }}
		</p>
		<btn
			v-if="getAction"
			v-bind="getAction"
			:colour="getActionColour"
			size="sm"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>
	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		},
		props: {
			row: {
				type: Object,
				default: undefined
			}
		},
		computed: {
			getInfoText () {
				return this.row?.infoText;
			},
			getAction () {
				return this.row?.action;
			},
			getActionColour () {
				if (this.row?.isImportant) {
					return 'red';
				}
				return 'grey';
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='action-group-row'] {
		display:flex;
		align-items:center;
		padding:rem(22) rem(24);

		[data-element='info-text'] {
      @include font(16px, $c-dark-navy, 700, 18px)
		}

		[data-component='btn'] {
			margin:auto 0 auto auto;
      @include font(16px, $c-white-l, 500, 20px)
		}
	}

</style>
